<template>
    <div class="upload_img" :style="[allstyle]">
        <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="uploadimg">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <div v-else class="avatar-uploader-icon" :style="{ background: 'url(' + bgImg + ')', backgroundSize: 'cover' }">
                <img :src="require('@/assets/images/person/accountManage/' + icon)" alt="">
            </div>
        </el-upload>
        <div class="txt">{{ label }}</div>
    </div>
</template>
<script>

export default {
    props: {
        width: {
            type: String,
            default: '200px'
        },
        height: {
            type: String,
            default: '125px'
        },
        bg_img: {
            type: String,
            default: 'bind3.png'
        },
        icon: {
            type: String,
            default: 'bind6.png'
        },
        label: {
            type: String,
            default: ''
        },
        imageUrl: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            // imageUrl: '',
            is_img: ['image/gif', 'image/png', 'image/jpg', 'image/jpeg'],

        }
    },
    computed: {
        allstyle() {
            return {
                '--uploadW': this.width,
                '--uploadH': this.height,
            }
        },
        bgImg() {
            return require('@/assets/images/person/accountManage/' + this.bg_img)
        },
    },
    watch: {

    },
    methods: {
        // 上传图片
        uploadimg(item) {
            var self = this
            // 限制图片格式
            const isJPG = this.is_img.includes(item.file.type)
            // 限制图片大小
            const isLt2M = item.file.size / 1024 / 1024 <= 3;
            if (!isJPG) {
                self.$errMsg('上传图片只能是 JPG ,JPEG, PNG 格式!');
            }
            if (!isLt2M) {
                self.$errMsg('上传图片大小不能超过 2MB!');
            }
            if (isJPG && isLt2M) {
                let newFile = new FormData()
                newFile.append('type', 4); // type 图片大小限制 1：10M以内；2：15M；3：30M；4：3M
                newFile.append('file', item.file);
                self.$publicApi.uploadImg(newFile).then(res => {
                    if (res.code == 1000) {
                        // this.imageUrl = res.result.url
                        this.$emit('uploadSuccess', res.result.url)
                    } else {
                        self.$errMsg(res.message)
                    }
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.upload_img {
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        width: var(--uploadW);
        height: var(--uploadH);
        // background: url('~@/assets/images/person/accountManage/bind3.png');
        // background-size: cover;
        position: relative;

        img {
            width: 42px;
            height: 42px;
            position: absolute;
            top: calc(50% - (42px / 2));
            left: calc(50% - (42px / 2));
        }
    }

    .avatar {
        width: var(--uploadW);
        height: var(--uploadH);
        display: block;
    }

    .txt {
        font-size: 14px;
        color: #999999;
        width: var(--uploadW);
        text-align: center;
        line-height: 14px;
    }
}
</style>